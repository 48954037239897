/* tiMED SPA Config */
export default (function ledgerConfig() {
  var config = {
      local: {
          // serviceURL: 'http://localhost:8080/timeledger'
          serviceURL: 'https://ptt-qa.app.medcity.net:8443/timeledger'
      },
      dev: {
          serviceURL: 'http://e2maldappptt01b.unix.medcity.net:8080/timeledger'
      },
      qa: {
          serviceURL: 'https://ptt-qa.app.medcity.net:8443/timeledger'
      },
      uat: {
          serviceURL: 'https://ptt-uat.app.medcity.net:8443/timeledger'
      },
      prod: {
          serviceURL: 'https://hcatimed.com:8443/timeledger'
      }
  };
  return {
    getConfig: function(key) {
        var env;
        switch (window.location.hostname) {
            case "localhost":
            case "127.0.0.1":
                env = 'local';
                break;
            case "e2maldappptt01b.unix.medcity.net":
                env = 'dev';
                break;
            case "ptt-qa.app.medcity.net":
                env = 'qa';
                break;
            case "ptt-uat.app.medcity.net":
                env = 'uat';
                break;
            case "hcatimed.com":
            case "www.hcatimed.com":
                env = 'prod';
                break;
            default:
                throw('Unknown environment for: ' + window.location.hostname );
        }
        return config[env][key];
    }
  };
})()
