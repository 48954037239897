/**
 * ledger.js
 *
 * Application-specific code/logic for Time Ledger app.
 *
 * (c) 2016 HCA, Inc.
 *     Jon Sharp
 */
/* jshint esversion: 6 */
import ledgerConfig from './config.js'
import '../css/ledger.css'
import pkg from '../../package.json'
window.TimeLedger = (function () {
    return {
        user: null,
        app: null,
        userContracts: null,
        Timesheet: null,
        oauthUrl: ledgerConfig.getConfig('serviceURL') + '/oauth/authenticate/hca',
        apiBaseUrl: ledgerConfig.getConfig('serviceURL') + '/api', /** Base URL of REST API for TimeLedger */
        api2BaseUrl: ledgerConfig.getConfig('serviceURL') + '/api/v2', /** Base URL of REST API for TimeLedger */
        version: pkg.version,

        getMonthWeek: function (y, m, d) {
            var firstDay = new Date(y, m, 1).getDay()
            return Math.ceil((d + firstDay) / 7)
        },
        logout: function (context) {
            sessionStorage.removeItem('user')
            sessionStorage.removeItem('authResponse')
            window.location.href = '/'
            $.ajax({
                url: TimeLedger.apiBaseUrl + '/logout',
                type: 'POST',
                data: '',
                context: context
            })
        }
    }
})()
export default {TimeLedger}
/* Set up logger: */
log.setLevel('error')

/* Set up some globals: */
var User, Contract, Entry, Task, Facility, Division
var UserList, ContractList, TimesheetList, EntryList, TaskList, GroupPayPeriodList, GroupPayPeriod, FacilityList
var EntryView, TimesheetView, AppView, GroupPayPeriodView, GroupPayPeriodsView, GroupRejectCommentsView, HelpView, LoginView
var AppRouter
var userTasks // eslint-disable-line no-unused-vars

/* Some constants: */
var ENTER_KEY = 13

/**
 * Represents a User domain model.
 */
User = Backbone.Model.extend({
    defaults: function () {
        return {
            userName: '',
            firstName: '',
            lastName: '',
            roles: null,
            contracts: null,
            contractors: null,
            delegates: null,
            currentContractor: 0
        }
    },
    urlRoot: TimeLedger.api2BaseUrl + '/users',
    url: function () {
        if (this.id) {
            return this.urlRoot + '/' + this.id
        } else {
            return this.urlRoot + '/byname/' + this.get('userName')
        }
    },
    initialize: function () {
        this.contracts = new ContractList()
        this.contracts.url = this.urlRoot + '/' + this.id + '/contracts'
        this.contractors = new UserList()
        this.delegates = new UserList()
    },
    parse: function (response) {
        log.debug('User.parse(): ' + JSON.stringify(response))
        if (response.id !== undefined) {
            // ensure that contracts url is set correctly based on newly-fetched ID:
            this.id = response.id
            this.contracts.url = this.urlRoot + '/' + this.id + '/contracts'
            if (response.contracts && response.contracts.length) {
                _.each(response.contracts, function (contract) {
                    if (['FINALIZED', 'EXPIRED', 'PENDING_TERMINATION', 'TERMINATED'].includes(contract.status)) {
                        this.contracts.add(new Contract(contract))
                    }
                }, this)
            } else {
                this.contracts.fetch()
            }
            _.each(response.contractors, function (contractor) {
                this.contractors.add(new User(contractor))
            }, this)
            _.each(response.delegates, function (delegate) {
                this.delegates.add(new User(delegate))
            }, this)
        }
        // fetch and populate contractors
        this.contractors.each(function (it) {
            it.fetch()
        })
        $('#contractor').trigger('change')
        return response
    },

    isGroupAdmin: function () {
        var groupAdmin = false

        if (this.get('roles')) {
            this.get('roles').forEach(function (role) {
                if (role === 'ROLE_GROUP_CONTRACT_ADMIN') {
                    groupAdmin = true
                }
            })
        }

        return groupAdmin
    }
})

/**
 * Represents a Contract domain model.
 */
Contract = Backbone.Model.extend({
    defaults: function () {
        return {
            coid: null,
            contractLevel: null,
            contractNumber: null,
            contractedFacilities: null,
            contractor: null,
            creationDate: null,
            dateCreated: null,
            description: null,
            division: null,
            endDate: null,
            executedDate: null,
            jobTitle: null,
            lastUpdated: null,
            startDate: null,
            status: null,
            supplementNumber: null,
            tasks: null,
            termDate: null,
            timesheets: null,
            totalObligation: null,
            timesheetStartDate: null,
            timesheetStopDate: null,
            effectiveDate: null
        }
    },
    urlRoot: TimeLedger.api2BaseUrl + '/contracts',
    initialize: function () {
        this.tasks = new TaskList()
        this.contractedFacilities = new FacilityList()
        this.timesheets = new TimesheetList()
        this.timesheets.on('reset', this.updateCounts)
    },
    parse: function (response) {
        log.debug('Contract.parse(): ' + JSON.stringify(response))

        this.timesheets = new TimesheetList()
        if (TimeLedger.app.user.contractors.length === 0) {
            this.timesheets.url = this.urlRoot + '/' + this.id + '/users/' + TimeLedger.app.user.id + '/timesheets'
        } else {
            this.timesheets.url = this.urlRoot + '/' + this.id + '/users/' + (TimeLedger.user.currentContractor === 0 ? TimeLedger.user.id : TimeLedger.user.currentContractor) + '/timesheets'
        }
        log.debug('Timesheet URL: ' + this.timesheets.url)

        this.contractedFacilities = new FacilityList()
        this.tasks = new TaskList()
        if (response.id !== undefined) {
            _.each(response.contractedFacilities, function (facility) {
                this.contractedFacilities.add(new Facility(facility))
            }, this)
            _.each(response.tasks, function (task) {
                this.add(new Task(task))
            }, this.tasks)
            this.division = new Division(response.division)
        }
        // fetch and populate the facilities
        this.contractedFacilities.each(function (it) {
            it.fetch()
            this.trigger('change')
        }, this)
        // fetch and populate the contract-specific TaskList:
        this.tasks.each(function (it) {
            it.fetch()
        })
        // and the division
        this.division.fetch()
        return response
    }
})

/**
 * Represents a Timesheet domain model.
 */
TimeLedger.Timesheet = Backbone.Model.extend({
    defaults: function () {
        return {
            id: null,
            user: null,
            startDate: null,
            endDate: null,
            approvers: null,
            approver: null,
            approvalDate: null,
            entries: null,
            notes: null,
            comments: null,
            contract: null,
            status: null,
            totalHours: 0.0,
            facilityTotals: null,
            groups: null,
            weeks: null,
            weeklyTotal: null,
            expiration: 90,
            noTimeWorked: null
        }
    },
    initialize: function () {
        if (this.entries === undefined) {
            this.entries = new EntryList()
            this.entries.url = this.urlRoot + '/' + this.id + '/entries'
        }
        this.groups = []
        this.weeks = []
    // this.entries.on('change', this.syncHandler);
    },
    syncHandler: function (collection, options) {
        this.weeks = [{
            weekId: 1,
            entries: [],
            weeklyGroupTotal: 0
        }, {
            weekId: 2,
            entries: [],
            weeklyGroupTotal: 0
        }, {
            weekId: 3,
            entries: [],
            weeklyGroupTotal: 0
        }, {
            weekId: 4,
            entries: [],
            weeklyGroupTotal: 0
        }, {
            weekId: 5,
            entries: [],
            weeklyGroupTotal: 0
        }, {
            weekId: 6,
            entries: [],
            weeklyGroupTotal: 0
        }]
        log.debug('syncHandler()')
        // log.debug(eventType);
        log.debug(collection)
        log.debug(options)
        log.debug(this)
        var totalHours = _.reduce(collection.models, function (memo, val) {
            return memo + parseFloat(val.get('hours'))
        }, 0.0)
        log.debug('totalHours: ' + totalHours)
        this.set({
            totalHours: totalHours
        })
        for (var i = 0; i < collection.length; i++) {
            let myModel = collection.models[i]
            var date = myModel.get('day')
            log.debug('Date :' + date)
            var year = moment(date, 'YYYY-MM-DD').year()
            var month = moment(date, 'YYYY-MM-DD').month()
            var day = moment(date, 'YYYY-MM-DD').date()
            log.debug('YEAR : ' + year + '   MONTHS :' + month + '  day ' + day)
            let weekNu = TimeLedger.getMonthWeek(year, month, day)
            this.weeks[weekNu - 1].entries.push(myModel.get('hours'))
            let weeklyTotal = this.weeks[weekNu - 1].entries.reduce(function (a, b) {
                return a + b
            }, 0)
            this.weeks[weekNu - 1].weeklyGroupTotal = weeklyTotal
        }
    },

    syncEntries: function (collection) {
        this.weeks = [{
            weekId: 1,
            entries: [],
            weeklyGroupTotal: 0
        }, {
            weekId: 2,
            entries: [],
            weeklyGroupTotal: 0
        }, {
            weekId: 3,
            entries: [],
            weeklyGroupTotal: 0
        }, {
            weekId: 4,
            entries: [],
            weeklyGroupTotal: 0
        }, {
            weekId: 5,
            entries: [],
            weeklyGroupTotal: 0
        }, {
            weekId: 6,
            entries: [],
            weeklyGroupTotal: 0
        }]
        for (var i = 0; i < collection.length; i++) {
            let myModel = collection.models[i]
            var date = myModel.get('day')
            log.debug('Date :' + date)
            var year = moment(date, 'YYYY-MM-DD').year()
            var month = moment(date, 'YYYY-MM-DD').month()
            var day = moment(date, 'YYYY-MM-DD').date()
            log.debug('YEAR : ' + year + '   MONTHS :' + month + '  day ' + day)
            let weekNu = TimeLedger.getMonthWeek(year, month, day)
            this.weeks[weekNu - 1].entries.push(myModel.get('hours'))
            let weeklyTotal = this.weeks[weekNu - 1].entries.reduce(function (a, b) {
                return a + b
            }, 0)
            this.weeks[weekNu - 1].weeklyGroupTotal = weeklyTotal
        }
    },

    getTotalHours: function () {
        return _.reduce(this.entries.models, function (memo, val) {
            return memo + parseFloat(val.get('hours'))
        }, 0.0)
    },

    getFacilityTotals: function () {
        var facilityTotals = {}
        _.mixin({
            totalHours: function (models) {
                return _.reduce(models, function (memo, val) {
                    return memo + parseFloat(val.get('hours'))
                }, 0.0)
            }
        })
        facilityTotals = _.chain(this.entries.models)
            .groupBy(function (entry) {
                return entry.get('facility').name
            })
            .map(function (value, key) {
                return {
                    id: key,
                    total: _.totalHours(value),
                    facility: new Facility({
                        id: key
                    })
                }
            })
            .value()
        return facilityTotals
    },
    blacklist: ['entries'],
    toJSON: function (options) {
        return _.omit(this.attributes, this.blacklist)
    },
    parse: function (response) {
        log.debug('Timesheet.parse(): ' + JSON.stringify(response))
        log.debug('Timesheet.parse(): ' + response.id)

        this.approvers = response.approvers
        if (response.id !== undefined) {
            if (response.entries !== undefined) {
                this.entries = new EntryList((response.entries ? response.entries : []))
                // Add a listeneer on the add method because this object is new and the
                // default timesheet view listener doesn't exist.
                this.entries.on('add', function () { Backbone.trigger('add-entries-event', this.entries) })
                this.entries.on('change', function () { Backbone.trigger('change-entries-event', this) })
            } else {
                this.entries.url = TimeLedger.api2BaseUrl + '/timesheets/' + response.id + '/entries'
                this.entries.fetch()
            }

            this.entries.on('change', this.syncHandler, this)
            this.entries.on('update', this.syncHandler, this)
            this.contract = new Contract(response.contract)
        }
        return response
    },
    validate: function (attrs, options) {
        var errors = []
        if (attrs.comments !== undefined && attrs.comments !== null && attrs.comments.length > 750) {
            errors.push({
                name: 'comments',
                message: 'Comments field must not exceed 750 characters'
            })
        }
        return errors.length > 0 ? errors : false
    },
    submit: function (successCallback, errorCallback, model, delegate) {
        var jsonData = this.toJSON()
        jsonData.user = {
            id: (TimeLedger.user.currentContractor === 0 ? TimeLedger.user.id : TimeLedger.user.currentContractor)
        }
        var timesheetStatus = model.get('status')
        var rootString = timesheetStatus === 'RECALL' ? '/recall' : '/submit'
        var authResponse = JSON.parse(sessionStorage.authResponse)
        $.ajax({
            url: TimeLedger.api2BaseUrl + '/timesheets/' + this.id + rootString,
            type: 'PUT',
            data: JSON.stringify(jsonData),
            dataType: 'json',
            contentType: 'application/json',
            headers: {
                'Authorization': 'Bearer ' + authResponse.id_token
            },
            success: function (data) {
                successCallback(model, data, delegate)
            },
            error: function (xhr, errorType, error) {
                errorCallback(xhr, errorType, error)
            }
        })
    },
    print: function (successCallback, errorCallback, model) {
        var jsonData = this.toJSON()
        jsonData.user = {
            id: (TimeLedger.user.currentContractor === 0 ? TimeLedger.user.id : TimeLedger.user.currentContractor)
        }
        var authResponse = JSON.parse(sessionStorage.authResponse)
        $.ajax({
            url: TimeLedger.api2BaseUrl + '/timesheets/' + this.id + '/export',
            type: 'GET',
            xhrFields: {
                responseType: 'blob'
            },
            headers: {
                'Authorization': 'Bearer ' + authResponse.id_token
            },
            success: function (data, status, xhr) {
                var blob = new Blob([data], {type: 'application/pdf;charset=utf-8;'})
                var pdfURL = null
                if (window.navigator.msSaveBlob) {
                    pdfURL = window.navigator.msSaveBlob(blob, 'Timesheet.pdf')
                } else {
                    pdfURL = window.URL.createObjectURL(blob)
                }

                var tempLink = document.createElement('a')

                tempLink.href = pdfURL
                tempLink.setAttribute('download', 'Timesheet.pdf')
                tempLink.click()
            },
            error: function (xhr, errorType, error) {
                errorCallback(xhr, errorType, error)
            }
        })
    }

})

/**
 * Represents an Entry domain model, belonging to a Timesheet.
 */
Entry = Backbone.Model.extend({
    defaults: function () {
        return {
            id: null,
            day: null,
            hours: null,
            task: null,
            user: null,
            facility: null,
            facilityName: null,
            reference: '',
            timesheet: null,
            notes: '',
            jobDefinition: null,
            projectCode: null
        }
    },
    urlRoot: TimeLedger.api2BaseUrl + '/entries',
    facilityCallback: function (facility) {
        log.debug('fetched facility: ' + JSON.stringify(facility))
    // this.entry.facilityName = facility.get('name');
    },
    initialize: function () {
        log.debug('Entry.init(): ')
        log.debug(this)
        if (this.timesheet !== undefined) {
            if (this.timesheet.contract !== undefined) {
                this.facility = this.timesheet.contract.contractedFacilities.first()
            }
        }
    },
    validate: function (attrs, options) {
        var errors = []
        if (attrs.day === null || attrs.day === '' || attrs.day === undefined) {
            errors.push({
                name: 'day',
                message: 'Must select a valid day'
            })
        }
        var taskSelected = true
        if (attrs.task.id === null || attrs.task.id === '' || attrs.task.id === undefined || isNaN(attrs.task.id)) {
            errors.push({
                name: 'task',
                message: 'Must select a valid job duty'
            })
            taskSelected = false
        }

        var jobDuty = $('select#task-' + (attrs.timesheet.id))
        var requireComment = false
        if (taskSelected && jobDuty) {
            var jobDutySelectedOption = jobDuty.find('option:selected')
            jobDutySelectedOption = jobDutySelectedOption.filter(function (j) { return parseInt($(this).attr('value')) === parseInt(attrs.task.id) }).first()
            requireComment = jobDutySelectedOption.data('requirecomment') === 'true' || jobDutySelectedOption.data('requirecomment') === true
        }

        if (requireComment && (attrs.notes === null || attrs.notes === '' || attrs.notes === undefined || attrs.notes.trim() === '')) {
            errors.push({
                name: 'notes',
                message: 'Detailed description must not be blank'
            })
        }
        if (attrs.hours <= 0) {
            errors.push({
                name: 'hours',
                message: 'Hours worked must be greater than 0'
            })
        }
        if (attrs.hours % 0.25 !== 0) {
            errors.push({
                name: 'hours',
                message: 'Hours must be entered in quarter-hour increments'
            })
        }
        if (attrs.hours > 24) {
            errors.push({
                name: 'hours',
                message: 'Hours on single entry cannot be greater than 24'
            })
        }
        return errors.length > 0 ? errors : false
    },
    parse: function (response) {
        log.debug('Entry.parse(): ' + JSON.stringify(response))
        log.debug('Entry.parse(): ' + response.id)
        if (response.length > 0) {
            response = response[0] // wrapped in an array now
        }
        // _.bind(this.facilityCallback, { entry: this });
        if (response.id !== undefined) {
            log.debug('Entry.parse(): facility:' + JSON.stringify(response.facility))
            this.facility = new Facility(response.facility)
            // this.facility.fetch({ success: this.facilityCallback });
        }
        return response
    },
    toJSON: function (options) {
        if (this.id) {
            return _.clone(this.attributes)
        } else {
            return [_.clone(this.attributes)]
        }
    }
})

/**
 * Represents a Task domain model.
 */
Task = Backbone.Model.extend({
    defaults: function () {
        return {
            taskName: '',
            shortDescription: '',
            longDescription: '',
            jobDefinition: null,
            requireProjectCode: null,
            requireComment: null
        }
    },
    urlRoot: TimeLedger.api2BaseUrl + '/tasks'
})

/**
 * Represents a Facility domain model.
 */
Facility = Backbone.Model.extend({
    defaults: function () {
        return {
            id: null,
            name: 'Facility',
            coid: '12345'
        }
    },
    urlRoot: TimeLedger.api2BaseUrl + '/facilities'
})

/**
 * Represents a Division domain model.
 */
Division = Backbone.Model.extend({
    defaults: function () {
        return {
            id: null,
            name: '',
            coid: ''
        }
    },
    urlRoot: TimeLedger.api2BaseUrl + '/divisions'
})

/*
// Collections:

/**
 * UserList defines a collection of User objects.
 */
UserList = Backbone.Collection.extend({
    model: User,
    url: TimeLedger.api2BaseUrl + '/users'
})

/**
 * TaskList defines a collection of Task objects.
 */
TaskList = Backbone.Collection.extend({
    model: Task,
    url: TimeLedger.api2BaseUrl + '/tasks'
})

/**
 * ContractList defines a collection of Contract objects.
 */
ContractList = Backbone.Collection.extend({
    model: Contract,
    url: TimeLedger.api2BaseUrl + '/contracts',
    comparator: function (one, two) {
        if (moment(one.get('effectiveDate')).isBefore(moment(two.get('effectiveDate')))) {
            return 1
        } else {
            return -1
        }
    }
})

/**
 * FacilityList defines a collection of Facility objects.
 */
FacilityList = Backbone.Collection.extend({
    comparator: 'name',
    model: Facility,
    url: TimeLedger.api2BaseUrl + '/facilities'
})

/**
 * RoleList defines a collection of Role objects.
 */
/* does not appear to be used
RoleList = Backbone.Collection.extend({
  url: TimeLedger.apiBaseUrl + '/roles'
})
*/
/**
 * TimesheetList defines a collection of Timesheet objects.
 */
TimesheetList = Backbone.Collection.extend({
    /** Default Timesheet comparator sorts a Timesheet collection descending by startDate. */
    comparator: function (one, two) {
        var weights = {
            'REJECTED': 7,
            'GROUP_REJECTED': 7,
            'REVIEW': 6,
            'OPEN': 5,
            'RECALL_REQUESTED': 4,
            'SUBMITTED': 3,
            'GROUP_SUBMITTED': 3,
            'APPROVED': 2,
            'LAPSED': 1
        }
        if (one.get('status') === two.get('status')) {
            if (moment(one.get('startDate')).isBefore(moment(two.get('startDate')))) {
                return -1
            } else {
                return 1
            }
        } else {
            return weights[two.get('status')] - weights[one.get('status')]
        }
    },
    model: TimeLedger.Timesheet
})

EntryList = Backbone.Collection.extend({
    model: Entry,
    url: TimeLedger.api2BaseUrl + '/entries',
    comparator: function (one, two) {
        if (moment(one.get('day')).isBefore(moment(two.get('day')))) {
            return -1
        } else {
            return 1
        }
    }
})

/**
     * Represents a Timesheet domain model.
     */
GroupPayPeriod = Backbone.Model.extend({
    defaults: function () {
        return {
            id: null,
            startDate: null,
            endDate: null,
            timesheets: null,
            status: null,
            hasOpenTimesheetWithTimeEntry: false
        }
    },
    urlRoot: TimeLedger.api2BaseUrl + '/group/timesheets',
    initialize: function () {
    },

    print: function (successCallback, errorCallback, model) {
        var jsonData = this.toJSON()
        jsonData.user = {
            id: (TimeLedger.user.currentContractor === 0 ? TimeLedger.user.id : TimeLedger.user.currentContractor)
        }
        var authResponse = JSON.parse(sessionStorage.authResponse)
        $.ajax({
            url: TimeLedger.api2BaseUrl + '/group/timesheets/' + this.id + '/export',
            type: 'GET',
            xhrFields: {
                responseType: 'blob'
            },
            headers: {
                'Authorization': 'Bearer ' + authResponse.id_token
            },
            success: function (data, status, xhr) {
                var blob = new Blob([data], {type: 'application/pdf;charset=utf-8;'})
                var pdfURL = null
                if (window.navigator.msSaveBlob) {
                    pdfURL = window.navigator.msSaveBlob(blob, 'Timesheet.pdf')
                } else {
                    pdfURL = window.URL.createObjectURL(blob)
                }

                var tempLink = document.createElement('a')

                tempLink.href = pdfURL
                tempLink.setAttribute('download', 'Timesheet.pdf')
                tempLink.click()
            },
            error: function (xhr, errorType, error) {
                errorCallback(xhr, errorType, error)
            }
        })
    },

    getTotalHours: function () {
        return _.reduce(this.get('timesheets'), function (memo, timesheet) {
            return _.reduce(timesheet.entries, function (memo, entry) {
                return memo + parseFloat(entry.hours)
            }, memo)
        }, 0.0)
    },

    parse: function (response) {
        log.debug('GroupPayPeriod.parse(): ' + JSON.stringify(response))
        log.debug('GroupPayPeriod.parse(): ' + response.id)

        return response
    },

    submit: function (successCallback, errorCallback, model, delegate) {
        var jsonData = this.toJSON()
        jsonData.user = {
            id: (TimeLedger.user.currentContractor === 0 ? TimeLedger.user.id : TimeLedger.user.currentContractor)
        }
        var authResponse = JSON.parse(sessionStorage.authResponse)
        $.ajax({
            url: TimeLedger.api2BaseUrl + '/group/submit/' + this.id,
            type: 'POST',
            data: JSON.stringify(jsonData),
            dataType: 'json',
            contentType: 'application/json',
            headers: {
                'Authorization': 'Bearer ' + authResponse.id_token
            },
            success: function (data) {
                successCallback(model, data, delegate)
            },
            error: function (xhr, errorType, error) {
                errorCallback(xhr, errorType, error)
            }
        })
    }
})

/**
     * TimesheetList defines a collection of Timesheet objects.
     */
GroupPayPeriodList = Backbone.Collection.extend({
    /** Default GroupPayPeriodList comparator sorts a GroupPayPeriodList collection descending by startDate. */
    comparator: function (one, two) {
        var weights = {
            'REJECTED': 7,
            'GROUP_REJECTED': 7,
            'REVIEW': 6,
            'OPEN': 5,
            'RECALL_REQUESTED': 4,
            'SUBMITTED': 3,
            'GROUP_SUBMITTED': 3,
            'APPROVED': 2,
            'LAPSED': 1
        }
        if (one.get('status') === two.get('status')) {
            if (moment(one.get('startDate')).isBefore(moment(two.get('startDate')))) {
                return -1
            } else {
                return 1
            }
        } else {
            return weights[two.get('status')] - weights[one.get('status')]
        }
    },
    model: GroupPayPeriod,
    url: TimeLedger.api2BaseUrl + '/group/timesheets'
    /*
          parse: function(response) {
            return response.timesheets;
          }
        */
})

/**
 * Definition of Application Router.
 */
AppRouter = Backbone.Router.extend({
    routes: {
        'submitTimesheet': 'submitTimesheet',
        'timesheet': 'timesheet',
        '': 'home'
    },
    initialize: function () {
        var root = window.location.pathname.replace('/', '').split('/')[0]
        Backbone.history.start({
            pushState: true,
            hashChange: false,
            root: root
        })
        this.__bindAnchorEventListeners()
    },
    __bindAnchorEventListeners: function () {
        var self = this
        $('a[href^="/"]').on('click', function (event) {
            event.preventDefault()
            var url = $(event.currentTarget).attr('href').replace(/^\//, '').replace(/#|!|\//g, '')
            self.navigate(url, {
                trigger: true
            })
        })
    },
    home: function () {
        log.debug('starting app...')
        TimeLedger.app = new AppView()
    },
    help: function () {},
    submitTimesheet: function () {},
    timesheet: function () {
        log.error('TIMESHEET ROUTE!')
    }
})

/**
 * Function initializes Backbone Views, including main App view.
 */
var initViews = function () {
    /**
     * EntryView defines the View/Controller logic for an Entry domain model.
     */
    EntryView = Backbone.View.extend({

        tagName: 'li',
        className: 'entry',

        template: _.template($('#timeentry-template').html()),

        events: {
            'click .editBtn': 'edit',
            'click .deleteBtn': 'deleteDialog',
            'click .yesButton': 'deleteModel',
            'click .noButton': 'cancelDelete',
            'click .saveBtn': 'save',
            'click .cancelBtn': 'cancel',
            'change .jobDefinition': 'updateJobDefinition',
            'change .jobDuty': 'toggleProjectCode',
            'input .notesTextArea': 'toggleProjectCode'
        },

        initialize: function () {
            log.debug('timesheet:' + this.model.get('timesheet'))
            this.new_e = null
            this.timesheet = this.model.timesheet
            this.workingAttributes = {}
            if (this.model.isNew()) {
                log.debug('This is a new model!')
                log.debug(this.timesheet.contract)
                this.edit()
            }
            this.workingAttributes = _.clone(this.model.attributes)
            // this.listenTo(this.model, 'sync', this.render);
            this.listenTo(this.model, 'destroy', this.remove)
            // this.model.on('invalid', function(model, error) { alert("Alert: " + error); });
            _.bindAll(this, 'errorCallback', 'successCallback')
        },

        render: function () {
            log.debug('EntryView.render():')
            log.debug(this.model)
            var templateVars = this.workingAttributes
            templateVars.timesheet = this.model.timesheet
            this.$el.html(this.template(templateVars))
            this.$el.attr('id', 'entry-' + templateVars.id)
            if (this.state === 'editing') {
                var id = Math.floor(Math.random() * 100000)
                this.$el.addClass('editing')
                this.$el.attr('uiattr', 'uiattr-' + id)
                this.$el.find('#projCodeLabel').attr('uiattr', 'uiattr-' + id)
            }
            this.input = this.$('.edit')

            this.$el.find('#task-' + this.model.timesheet.id).select2({
                multiple: false,
                minimumResultsForSearch: Infinity,
                placeholder: 'Tap/click to select',
                allowClear: true
            })
            setTimeout(() => {
                if (this.workingAttributes.projectCode == null) {
                    this.$el.find('#projectCode-').hide()
                    this.$el.find('#projCodeLabel').hide()
                    this.$el.find('#projectCode-').attr('shown', false)
                    this.$el.find('#nLabel1').css('margin-left', 0)
                    this.$el.find('#nLabel2').css('margin-left', 0)
                }
                this.$el.find('#nLabel1').css('margin-left', 0)
                this.$el.find('#nLabel2').css('margin-left', 0)
            }, 10)
            return this
        },

        toggleProjectCode: function (e) {
            var el = e.target
            if ($(el).hasClass('notesTextArea')) {
                el = el.closest('li')
                el = $(el).find('select[id^="task"]')
                el = el.find('option:selected')
            } else {
                el = $(el).find('option:selected')
            }

            var reqprojectCode = el.data('requireprojcode')
            var reqComment = el.data('requirecomment')

            if (reqComment === 'false' || reqComment === false) {
                this.$el.find('[id^="notes-"]').attr('title', 'Enter job duty comments(optional) \n(Max 450 characters)')
                this.$el.find('[id^="notes-"]').attr('placeholder', 'Enter job duty comments(optional) \n(Max 450 characters)')
            } else {
                this.$el.find('[id^="notes-"]').attr('title', 'Enter job duty comments(required) \n(Max 450 characters)')
                this.$el.find('[id^="notes-"]').attr('placeholder', 'Enter job duty comments(required) \n(Max 450 characters)')
            }

            if (reqprojectCode === 'true' || reqprojectCode === true) {
                this.$el.find('[id^="projectCode"]').show()
                this.$el.find('[id^="projCodeLabel"]').show()
                this.$el.find('[id^="projectCode"]').attr('shown', true)
            } else {
                this.$el.find('[id^="projectCode"]').hide()
                this.$el.find('[id^="projCodeLabel"]').hide()
                this.$el.find('[id^="projectCode"]').attr('shown', false)
            }
        },
        updateJobDefinition: function () {
            var day = this.$el.find('[id^=day]').val()
            var hours = this.$el.find('[id^=hours]').val()
            var facilityId = this.$el.find('[id^=facility]').val()
            var notes = this.$el.find('[id^=notes]').val()
            var e = this.$el.find('#job-definition-' + this.model.timesheet.id)
            var projectCode = this.$el.find('[id^=projectCode]').val()

            this.workingAttributes['day'] = day
            this.workingAttributes['hours'] = hours
            if (!this.workingAttributes['facility']) {
                this.workingAttributes['facility'] = {}
            }
            this.workingAttributes['facility']['id'] = parseInt(facilityId)
            this.workingAttributes['notes'] = notes
            if (!this.workingAttributes['jobDefinition']) {
                this.workingAttributes['jobDefinition'] = {}
            }
            this.workingAttributes['jobDefinition']['id'] = parseInt(e.val())
            this.workingAttributes['projectCode'] = projectCode

            console.log('Job definition changed to: ' + e.val())
            TimeLedger.app.selectJobDefinition(e.val())
            this.render()
        },

        edit: function () {
            this.state = 'editing'
            var id = Math.floor(Math.random() * 100000)
            this.$el.addClass('editing')
            this.$el.attr('uiattr', 'uiattr-' + id)
            this.$el.find('#projCodeLabel').attr('uiattr', 'uiattr-' + id)
            if (this.workingAttributes.projectCode != null) {
                this.$el.find('[id^="projCodeLabel"]').show()
                this.$el.find('[id^="projectCode"]').attr('shown', true)
                this.$el.find('#projectCode-').show()
            } else {
                this.$el.find('[id^="projectCode"]').hide()
                this.$el.find('#projCodeLabel').hide()
            }
        },

        deleteModel: function () {
            this.model.destroy()
        },

        deleteDialog: function () {
            var e = this.$el.find('#deleteConfirmation-' + this.model.id)
            e.children('div').css('top', 0)
            e.show()
        },

        cancelDelete: function () {
            this.$el.find('#deleteConfirmation-' + this.model.id).hide()
        },

        cancel: function () {
            this.workingAttributes = _.clone(this.model.attributes)
            this.render()
            if (!this.model.isNew()) {
                this.$el.removeClass('editing')
            }
        },

        save: function () {
            var day = this.$el.find('[id^=day]').val()
            var taskId = this.$el.find('[id^=task]').val()
            var hours = this.$el.find('[id^=hours]').val()
            var facilityId = this.$el.find('[id^=facility]').val()
            var notes = this.$el.find('[id^=notes]').val()
            var jobDefId = this.$el.find('[id^=job-definition]').val()
            var projectCode = this.$el.find('[id^=projectCode]').val()
            // save Day in session storage for convenient re-entry:
            sessionStorage.lastDay = day

            if (!TimeLedger.app.hasMultipleJobDefinitions) {
                this.model.attributes.jobDefinition = {
                    id: jobDefId
                }
            }
            var model = this.model
            var currentDayString = day.split('T')[0]
            var totalHours = _.reduce(this.model.timesheet.entries.models, function (sum, obj) {
                var nowDay = obj.attributes.day.split('T')[0]
                if (obj.cid !== model.cid && nowDay === currentDayString) {
                    sum = sum + parseFloat(obj.attributes.hours)
                }
                return sum
            }, 0.0)

            var errors = []
            if (totalHours + parseFloat(hours) > 24) {
                errors.push({
                    name: 'hours',
                    message: 'Hours on single entry cannot be greater than 24'
                })
                this.showErrors(errors)
                return
            }
            if (hours % 0.25 !== 0) {
                errors.push({
                    name: 'hours',
                    message: 'Hours must be entered in quarter-hour increments'
                })
                this.showErrors(errors)
                return
            }
            if (!taskId) {
                errors.push({
                    name: 'task',
                    message: 'Must select a valid job duty'
                })
                this.showErrors(errors)
                return
            }
            if (!facilityId) {
                errors.push({
                    name: 'facility',
                    message: 'Must select a valid facility'
                })
                this.showErrors(errors)
                return
            }

            if (this.$el.find('[id^=projectCode]').attr('shown') === 'true') {
                if (projectCode === '') {
                    errors.push({
                        name: 'projectCode',
                        message: 'Project Code is required and should be valid'
                    })
                    this.showErrors(errors)
                    return
                }
                if (projectCode.length < 6) {
                    errors.push({
                        name: 'projectCode',
                        message: 'Project Code must be 6 digits'
                    })
                    this.showErrors(errors)
                    return
                }
            }
            var checkProjectCode = this.$el.find('[id^=projectCode]').attr('shown') === 'true'

            if (TimeLedger.app.hasMultipleJobDefinitions) {
                this.model.attributes = {
                    'id': this.workingAttributes.id,
                    'day': day,
                    'task': {
                        'id': parseInt(taskId)
                    },
                    'jobDefinition': {
                        'id': parseInt(jobDefId)
                    },
                    'hours': hours,
                    'facility': {
                        'id': parseInt(facilityId)
                    },
                    'notes': notes,
                    'timesheet': {
                        'id': this.model.timesheet.id
                    },
                    'projectCode': checkProjectCode ? projectCode : null
                }
            } else {
                this.model.attributes = {
                    'id': this.workingAttributes.id,
                    'day': day,
                    'task': {
                        'id': parseInt(taskId)
                    },
                    'hours': hours,
                    'facility': {
                        'id': parseInt(facilityId)
                    },
                    'notes': notes,
                    'timesheet': {
                        'id': this.model.timesheet.id
                    },
                    'projectCode': checkProjectCode ? projectCode : null
                }
            }

            if (this.model.isValid()) {
                this.$el.find('.saveBtn').first().prop('disabled', true)
                this.$el.find('.saveBtn').first().html('<i class="fa fa-spin fa-spinner fa-pulse"></i> Save')
                this.model.save({}, {
                    error: this.errorCallback,
                    success: this.successCallback
                })
            } else {
                this.state = 'editing'
                this.$el.addClass('editing')
                log.debug('Model is not valid')
                this.hideErrors([{
                    name: 'day'
                }, {
                    name: 'task'
                }, {
                    name: 'hours'
                }, {
                    name: 'facility'
                }, {
                    name: 'notes'
                }, {
                    name: 'projectCode'
                }])
                this.showErrors(this.model.validationError)
            }
        },
        showErrors: function (errors) {
            console.log(errors)
            log.debug('showErrors()' + JSON.stringify(errors))
            this.$('.errors').text('')
            _.each(errors, function (error) {
                log.debug('showErrors() error:' + JSON.stringify(error))
                console.log(error.name)
                if (error.name !== undefined && error.name !== null) {
                    this.$el.find('[id^=' + error.name + ']').addClass('error')
                    log.debug(this.$el.find('[id^=' + error.name + ']'))
                }
                this.$el.find('.errors').append('<li>' + error.message + '</li>')
                log.debug(this.$el.find('.errors'))
            }, this)
        },
        hideErrors: function (errors) {
            log.debug('hideErrors()')
            _.each(errors, function (error) {
                this.$el.find('[id^=' + error.name + ']').removeClass('error')
            }, this)
            this.$('.errors').text('')
        },
        errorCallback: function (model, response, options) {
            var errors = []
            // remove spinner and re-enable saveBtn:
            this.$el.find('.saveBtn').first().html('Save')
            this.$el.find('.saveBtn').first().prop('disabled', false)
            if (response.status === 403) {
                TimeLedger.app.login()
                errorHandler(model, response, this)
            } else if (response.status === 500) {
                errors.push({
                    name: null,
                    message: 'Unexpected server error. Please try again'
                })
                this.showErrors(errors)
            } else if (options.textStatus === 'timeout') {
                errors.push({
                    name: null,
                    message: 'Request timed out. Please try again.'
                })
                this.showErrors(errors)
            } else {
                var resp = JSON.parse(response.response)
                var pattern = /Property \[(\w+)\]/
                var matcher = null
                // NOTE: Default to 'hours' as assumed field in error.
                var property = 'hours'
                if (resp._embedded) { // multiple errors returned
                    _.each(resp._embedded.errors, function (error) {
                        matcher = pattern.exec(error.message)
                        if (matcher !== null) {
                            log.debug('Found property: ' + JSON.stringify(matcher))
                            property = matcher[1]
                        }
                        errors.push({
                            name: property,
                            message: error.message
                        })
                    }, errors)
                } else { // single error returned
                    matcher = pattern.exec(resp.message)
                    if (matcher !== null) {
                        log.debug('Found property: ' + JSON.stringify(matcher))
                        property = matcher[1]
                        errors.push({
                            name: property,
                            message: resp.message
                        })
                    } else {
                        errors.push({
                            name: property,
                            message: resp.message
                        })
                    }
                }
                log.debug(errors)
                this.hideErrors([{
                    name: 'day'
                }, {
                    name: 'task'
                }, {
                    name: 'hours'
                }, {
                    name: 'facility'
                }, {
                    name: 'notes'
                }])
                this.showErrors(errors)
            }
        },
        successCallback: function (model, response, options) {
            log.debug('EntryView.successCallback():')
            log.debug(model)
            log.debug(response)
            log.debug(options)
            this.hideErrors(this.model.validationError)
            this.timesheet.entries.add(this.model)
            // log.error(this.$el.parent().siblings('.flash').first()); //.children('.flash'));
            // log.error('top: ' + t);
            // window.location.hash = '#entry-' + model.id;
            // smoothScroll($(window), t, 500);
            /*
                  this.$el.animate({
                    color: 'red'
                  }, 3000, 'ease-out');
            */
        }
    })

    /**
     * TimesheetView defines the View/Controller logic for a Timesheet domain model.
     */
    TimesheetView = Backbone.View.extend({

        tagName: 'li',
        className: 'timesheet',

        template: _.template($('#timesheet-template').html()),

        initialize: function () {
            this.state = 'closed' // default to closed
            this.loaded = true
            _.bindAll(this, 'processEntry', 'submitSuccessCallback', 'submitErrorCallback')
            this.listenTo(this.model, 'change', function () {
                this.render('change')
            })
            this.listenTo(this.model.entries, 'add', function () {
                log.debug('Invoked from Add')
                this.render('addEntry')
            })
            /*
            Add a listener to a global backbone event.  this is necessary when we are creating a
            new timesheet.  we are in the initialize method of the view, the entries do not
            exist, so we cannot bind to the .add et al method.  The timesheet model should invoke this
            when it creates the .entries object.  It's hacky, bu9t it works for now until the event
            models can be fixed.
            */
            this.listenTo(Backbone, 'add-entries-event', function () {
                this.render('addEntry')
            })
            this.listenTo(Backbone, 'change-entries-event', function (model) {
                this.updateEntry(model)
            })
            this.listenTo(this.model.entries, 'reset', this.render)
            this.listenTo(this.model.entries, 'change', this.updateEntry)
            this.listenTo(this.model.entries, 'remove', this.updateEntry)
            this.listenTo(this.model, 'sync', function () {
                this.render('change')
            })
            this.listenTo(this.model.entries, 'sync', function () {
                this.render('syncEntries')
            })
            $('.select-spinner').hide()
        },

        events: {
            'click .printButton': 'print',
            'click .timesheet-header': 'editsave',
            'click .submitButton': 'submit',
            'click .recallButton': 'submit',
            'click  .recallConfirm': 'recallSubmit',
            'click .confirmButton': 'submitConfirm',
            'click .cancelButton': 'submitCancel',
            'keydown .commentsArea': 'updateComments',
            'focusout .commentsArea': 'updateComments',
            'click #noTimeWorked': 'toggleEntryFields',
            'click .saveTsButton': 'saveTimesheet'
        },

        render: function (e) {
            // if (!(this.model.get('status') instanceof String)) { return this; }
            this.$el.addClass(this.model.get('status').toLowerCase())
            if (this.model.get('status').toLowerCase() === 'open') {
                this.$el.removeClass('submitted')
                this.$el.removeClass('recall')
                this.$el.removeClass('approved')
            }

            // log.debug('TimesheetView.render() ' + JSON.stringify(this.model.toJSON()))
            if (moment().isBetween(this.model.get('startDate'), this.model.get('endDate'))) {
                log.debug('Found a current timesheet')

                if (this.state !== 'editing' && ['OPEN', 'REJECTED', 'GROUP_REJECTED', 'REVIEW'].includes(this.model.status)) {
                    this.state = 'editing'
                }
            }

            if (e === 'submit') {
                this.state = 'closed'
            }

            this.model.syncEntries(this.model.entries)
            this.model.groups = []

            if (e !== 'change') {
                this.$el.html(this.template({model: this.model, status: this.model.status, startDate: this.model.startDate, noTimeWorked: this.model.get('noTimeWorked')}))
            }
            if (e === 'syncEntries') {
                this.model.entries.each(this.processEntry)
            }

            if (this.loaded || this.model.isNew()) {
                this.$el.find('.timesheet-spinner').removeClass('fa-spinner')
                this.$el.find('.timesheet-spinner').removeClass('fa-pulse')
            }

            // remove spinner after sync of entries:
            if (e === 'syncEntries') {
                this.loaded = true
                this.$el.find('.timesheet-spinner').removeClass('fa-spinner')
                this.$el.find('.timesheet-spinner').removeClass('fa-pulse')
            }

            this.$el.find('.saveTsButton').hide()
            this.$el.find('div.noTimeWorked').hide()

            if (this.state === 'editing') {
                this.$el.removeClass('view')
                this.$el.removeClass('closed')
                this.$el.addClass('editing')
                // log.debug('model:' + JSON.stringify(this.model));
                // log.debug('entries:' + JSON.stringify(this.model.entries));
                // add a new one at the end for quick entry (on OPEN or REJECTED timesheets):
                if (['OPEN', 'REJECTED', 'REVIEW', 'GROUP_REJECTED'].includes(this.model.get('status'))) {
                    var entry = new Entry()
                    entry.timesheet = this.model
                    log.debug('timesheet.startDate:' + entry.timesheet.get('startDate'))
                    entry.collection = this.model.entries
                    // add a new entry, if one doesn't already exist:
                    if (this.$el.find('.entry.editing').length === 0 || e === 'addEntry') {
                        var entryView = new EntryView({
                            model: entry
                        })
                        this.$el.find('ul.entries').prepend(entryView.render().el)
                    }
                    if (this.model.entries.length > 0) {
                        this.$el.find('input#noTimeWorked').first().prop('disabled', true)
                    }

                    if (this.model.get('noTimeWorked') === true) {
                        this.$el.find('input#noTimeWorked').first().prop('checked', true)
                        this.$el.find('input#noTimeWorkedDbVal').first().val('true')

                        this.toggleEntryFields(this.model)
                    } else {
                        this.$el.find('input#noTimeWorked').removeAttr('checked')
                    }

                    this.$el.find('.saveTsButton').hide()
                    if (this.$el.find('input#noTimeWorked').prop('checked') === true) {
                        this.$el.find('.saveTsButton').show()
                    }
                    // and show:
                    this.$el.find('div.noTimeWorked').show()
                    this.$el.find('ul.entryGroups').show()
                    this.$el.find('ul.entries').show()
                    this.$el.find('button.submitButton').show()
                    this.$el.find('button.printButton').show()
                    this.$el.find('div.comments').show() // comments field
                }
            } else if (this.state === 'viewing') {
                this.$el.addClass('view')
                this.$el.removeClass('closed')
                this.$el.removeClass('editing')
                // this.$el.find(".commentsArea").after('<span>' + this.model.get('comments') + '</span>');
                this.$el.find('.commentsArea').prop('disabled', true)
                this.$el.find('.comments').show()
            } else {
                this.$el.removeClass('editing')
                this.$el.removeClass('view')
                this.$el.addClass('closed')
            }

            // Set up checkbox validation:
            this.$el.find('input[type=checkbox].confirmationCheckbox').click(function (e) {
                $('.confirmButton').prop('disabled', !e.target.checked)
            })

            this.$el.find('#task-' + this.model.id).select2({
                multiple: false,
                minimumResultsForSearch: Infinity,
                placeholder: 'Tap/click to select',
                allowClear: true
            })

            return this
        },

        toggleEntryFields: function (model) {
            if (this.$el.find('input#noTimeWorked').prop('checked') === true) {
                this.$el.find('ul.entries').find('select, input, button, textarea').prop('disabled', true)
                this.$el.find('.saveTsButton').show()
                this.model.set('noTimeWorked', this.$el.find('input#noTimeWorked').prop('checked'))
            } else {
                this.model.set('noTimeWorked', false)
                this.$el.find('.saveTsButton').hide()
                this.$el.find('ul.entries').find('select, input, button, textarea').removeAttr('disabled')
            }
        },

        updateEntry: function (model) {
            // 2nd check is a hack because of how the event listener of change-entries-event is getting invoked
            // It does not have individual entry context, only the collection - so check the collection for errors.
            if (model.validationError === null || model.models.every(function (entry) { return entry.validationError === null })) {
                this.$el.find('.totalHours').html(' - ' + this.model.getTotalHours() + ' Hrs')
                this.render('syncEntries')
            }
        },
        /*
          Saving / Updating a timesheet uses a different end point than we use to retrieve timesheets
          This will update the URL to th save endpoint prior to the call being made
        */
        save: function () {
            this.contracts.timesheets.url = TimeLedger.api2BaseUrl + '/timesheets'
        },

        /** Callback for user edit/save events. */
        editsave: function () {
            log.debug('TimesheetView.editsave()...')
            if (this.state === 'viewing' || this.state === 'editing') {
                this.state = 'closed'
                this.close()
            } else {
                log.debug('TimesheetView.editsave(): ' + JSON.stringify(this.model.toJSON()))
                log.debug('TimesheetView.editsave(): ' + this.model.get('status'))
                if (this.model.get('status') === 'OPEN' || this.model.get('status') === 'REJECTED' || this.model.get('status') === 'GROUP_REJECTED' || this.model.get('status') === 'REVIEW') {
                    this.state = 'editing'
                } else {
                    this.state = 'viewing'
                }
                /*
                        if (this.$el.find("button").html().indexOf("View") >= 0) {
                            this.state = "viewing";
                        } else {
                            this.state = "editing";
                        }
                */
            }
            this.render('syncEntries')
        },

        saveTimesheet: function (e) {
            var jsonData = this.model.toJSON()
            jsonData.user = {
                id: (TimeLedger.user.currentContractor === 0 ? TimeLedger.user.id : TimeLedger.user.currentContractor)
            }
            // var timesheetStatus = model.get('status')
            var that = this
            var authResponse = JSON.parse(sessionStorage.authResponse)
            $.ajax({
                url: TimeLedger.api2BaseUrl + '/timesheets/' + this.model.id,
                type: 'PUT',
                data: JSON.stringify(jsonData),
                dataType: 'json',
                contentType: 'application/json',
                headers: {
                    'Authorization': 'Bearer ' + authResponse.id_token
                },
                success: function (data) {
                    that.submitSuccessCallback(that.model, data, false)
                },
                error: function (xhr, errorType, error) {
                    that.submitErrorCallback(xhr, errorType, error)
                }
            })
        },

        updateComments: function (e) {
            log.debug(e)
            var comments = this.$el.find('.comments textarea').val()

            if (e.type === 'focusout') {
                log.debug('TimesheeView.updateComments()!')
                this.model.set({
                    comments: comments
                })
                if (this.model.isValid()) {
                    this.model.save({}, {
                        silent: true,
                        success: function (model) {
                            log.debug('successfully saved Timesheet model: ' + model)
                        },
                        error: function (model) {
                            this.showErrors(model)
                        }
                    })
                } else {
                    this.showErrors(this.model.validationError)
                }
            }
            if (e.type === 'keydown') {
                var maxCharacters = 750 - comments.length
                if (maxCharacters <= 0) {
                    maxCharacters = 0
                }
                var remainCharacters = +comments.length + '/750 characters '
                this.$el.find('.countCharacters').text(remainCharacters)
            }
        },

        /** Callback for successful fetch of (Entry) model. */
        processEntry: function (entryModel, response) {
            // hydrate timesheet:
            log.debug('processEntry()!')
            log.debug(entryModel)
            // e.set('timesheet', this.model);
            log.debug('Entry: ' + entryModel.get('day') + ',' + entryModel.get('task') + ',' + entryModel.get('notes'))
            // $.extend(model, { timesheet: this.model }, true);
            // set the timesheet reference on the (entry) model to the timesheet object ("this.model" in this case):
            entryModel.timesheet = this.model
            entryModel.collection = this.model.entries
            var view = new EntryView({
                model: entryModel
            })
            log.debug('view:' + view)
            var date = entryModel.get('day')
            var d = moment(date, 'YYYY-MM-DD')
            var weekrange = ' ( week ending ' + d.endOf('week').format('MM/DD/YY') + ' ) '

            log.debug('Date :' + date)
            var year = moment(date, 'YYYY-MM-DD').year()
            var month = moment(date, 'YYYY-MM-DD').month()
            var day = moment(date, 'YYYY-MM-DD').date()
            log.debug('YEAR : ' + year + '   MONTHS :' + month + '  day ' + day)
            var weekNu
            var template_html
            weekNu = TimeLedger.getMonthWeek(year, month, day)
            log.debug('WEEK NUMBER :' + weekNu)
            var totalhours

            if (this.model.weeks[weekNu - 1]) {
                totalhours = this.model.weeks[weekNu - 1].weeklyGroupTotal
            }
            if (this.model.groups[weekNu] === undefined || this.model.groups[weekNu] === entryModel.id) {
                template_html = '<li><div class="groupHeader">Week ' + weekNu + weekrange + ' - ' + totalhours + ' Hours</div><ul class="week-' + weekNu + '"></ul></li>'
                this.$el.find('ul.entries').append(template_html)
                this.$el.find('ul.entries .week-' + weekNu).append(view.render().el)
                this.model.groups[weekNu] = entryModel.id
            } else {
                this.$el.find('ul.entries .week-' + weekNu).append(view.render().el)
            }

            log.debug(this.$el.find('ul.entries'))
        },

        /** Callback for user close event. */
        close: function () {
            log.debug('close()')
            this.state = 'closed'
            this.render()
            // this.$el.removeClass("editing");
            // this.$el.find("ul.entries").empty();
        },

        /** Print/export Timesheet as PDF */
        print: function () {
            this.model.print(this.submitSuccessCallback, this.submitErrorCallback, this.model)
        },

        /** Submission of Timesheet. */
        submit: function () {
            // save the comments on initial submit:
            /*
                  var e = new KeyboardEvent("keydown");
                  delete e.keyCode;
                  Object.defineProperty(e, "keyCode", { "value" : ENTER_KEY });
                  this.updateComments(e);
            */
            if (this.model.get('noTimeWorked') === false && this.model.entries.length === 0) {
                this.showErrors([{
                    name: 'entries',
                    message: 'You must provide at least one time entry in order to submit a timesheet.'
                }])
            } else {
                var submissionModalId = '#submission-' + this.model.id
                if (this.model.isValid() && (this.model.entries.length !== 0 || this.model.get('noTimeWorked') === true)) {
                    this.hideErrors()
                    this.$el.find(submissionModalId).show()
                    this.$el.find(submissionModalId).find('.timeWorked, .noTimeWorked').hide()
                    var attestMsgCls = this.model.get('noTimeWorked') === true ? '.noTimeWorked' : '.timeWorked'
                    this.$el.find(submissionModalId).find(attestMsgCls).show()
                } else {
                    this.$el.find(submissionModalId).hide()
                }
            }
        },

        submitSuccessCallback: function (model, data, delegate) {
            log.debug('submitSuccessCallback:')
            log.debug(this)
            log.debug(model)
            log.debug(data.status)
            log.debug(delegate)
            this.$el.find('input[type=checkbox]').prop('checked', false)
            this.$el.find('#submission-' + this.model.id).hide()
            this.$el.find('.confirmButton').first().html('Submit')
            // model.parse(data);
            log.debug(model)
            if (delegate === true) {
                this.model.set('status', 'REVIEW')
            } else {
                this.model.set('status', 'SUBMITTED')
            }
            if (data.status === 'RECALL_REQUESTED') {
                this.model.set('status', 'RECALL_REQUESTED')
            } else if (data.status === 'OPEN') {
                this.model.set('status', 'OPEN')
                this.model.set('lastUpdated', data.lastUpdated)
            } else if (data.status === 'APPROVED') {
                this.model.set('status', 'APPROVED')
            }

            // this.model = new Timesheet(data);
            this.render('submit')
            this.model.trigger('change')
        },

        submitErrorCallback: function (xhr, errorType, error) {
            log.debug('submitErrorCallback:')
            this.$el.find('input[type=checkbox]').prop('checked', false)
            this.$el.find('#submission-' + this.model.id).hide()
            this.$el.find('.confirmButton').first().html('Submit')
            this.$el.find('.recallConfirm').first().removeAttr('disabled')
            this.$el.find('.recallConfirm').first().find('i.fa-spinner').remove()
            if (errorType === 'timeout') {
                this.showErrors([{
                    name: null,
                    message: 'Request timed out.  Please try again.'
                }])
            } else if (xhr.status === 401) {
                TimeLedger.app.login()
            } else {
                var errorResponse = JSON.parse(xhr.responseText)
                this.showErrors([{
                    name: null,
                    message: 'Unexpected error: ' + errorResponse.message
                }])
                alert('Unexpected error: ' + errorResponse.message)
            }
        },

        submitConfirm: function () {
            this.$el.find('.confirmButton').first().prop('disabled', true)
            this.$el.find('.confirmButton').first().html('<i class="fa fa-spin fa-spinner fa-pulse"></i> Submit')
            this.model.submit(this.submitSuccessCallback, this.submitErrorCallback, this.model, TimeLedger.user.contractors.length > 0)
        },

        recallSubmit: function () {
            this.model.set('status', 'RECALL')
            this.$el.find('.recallConfirm').first().prop('disabled', true)
            this.$el.find('.recallConfirm').first().html('<i class="fa fa-spin fa-spinner fa-pulse"></i> Recall')
            this.model.submit(this.submitSuccessCallback, this.submitErrorCallback, this.model, TimeLedger.user.contractors.length > 0)
        },

        submitCancel: function () {
            this.$el.find('#submission-' + this.model.id).hide()
        },

        showErrors: function (errors) {
            log.debug('showErrors()' + JSON.stringify(errors))
            this.$('#timesheetErrors').text('')
            _.each(errors, function (error) {
                this.$el.find('#timesheetErrors').append('<li>' + error.message + '</li>')
            }, this)
        },

        hideErrors: function () {
            log.debug('hideErrors()')
            this.$('#timesheetErrors').text('')
        }
    })

    /**
     * Defines group pay periods list view
     */
    GroupPayPeriodsView = Backbone.View.extend({
        events: {
            'click .closeButton': 'closeDialog'
        },

        payPeriods: [],
        model: new GroupPayPeriodList(),

        initialize: function () {
            _.bindAll(this, 'render')
            this.contract = new Contract()

            this.model.fetch({
                success: this.render,
                error: function (model, response, options) {
                    log.debug(response)
                }
            })
        },

        render: function () {
            var self = this

            this.$el.empty()
            console.log(this.model)
            if (self.model.length) {
                self.model.models.forEach(function (aModel) {
                    var view = new GroupPayPeriodView({
                        model: aModel
                    })
                    self.$el.append(view.render().el)
                })
            } else {
                self.$el.append('<div>No active contracts are available for ' + TimeLedger.user.attributes.firstName + ' ' + TimeLedger.user.attributes.lastName + '</div>')
            }

            return this
        }

    })

    GroupPayPeriodView = Backbone.View.extend({
        tag: 'li',
        events: {
            'click .printButton': 'print',
            'click .closeButton': 'closeDialog'
        },
        className: 'group-payperiod-container',

        template: _.template($('#group-payperiod-template').html()),

        initialize: function () {
            _.bindAll(this, 'render')

            // this.listenTo(this.model, 'sync', this.render);
            this.model.fetch({
                success: this.render,
                error: function (model, response, options) {
                    log.error(response)
                }
            })
        },

        render: function () {
            console.log(this.model.get('noTimeWorked'))
            this.$el.html(this.template({model: this.model, status: this.model.status, startDate: this.model.startDate, timesheets: this.model.get('timesheets'), noTimeWorked: this.model.get('noTimeWorked')}))

            return this
        },

        /** Print/export Timesheet as PDF */
        print: function () {
            this.model.print($.noop, this.printErrorCallback, this.model)
        },

        printErrorCallback: function (xhr, errorType, error) {
            log.debug('printErrorCallback:')
            if (errorType === 'timeout') {
                this.showErrors([{
                    name: null,
                    message: 'Request timed out.  Please try again.'
                }])
            } else if (xhr.status === 401) {
                TimeLedger.app.login()
            } else {
                var errorResponse = JSON.parse(xhr.responseText)
                this.showErrors([{
                    name: null,
                    message: 'Unexpected error: ' + errorResponse.message
                }])
            }
        },

        totalHoursForTimesheet: function (timesheet) {
            var totalHours = 0.0
            if (timesheet && timesheet.entries) {
                _.each(timesheet.entries, function (entry, index, list) {
                    totalHours += parseFloat(entry.hours)
                })
            }
            return totalHours
        }

    })

    /**
     * Defines group reject comments modal
     */
    GroupRejectCommentsView = Backbone.View.extend({
        el: '#rejectCommentsModal',
        template: _.template($('#reject-comments-modal').html()),
        events: {
            'click .cancelButton': 'closeDialog'
        },
        render: function (parameters) {
            this.$el.html(this.template(parameters))
            this.$el.show()
            return this
        },
        closeDialog: function () {
            this.$el.hide()
        }
    })

    /**
     * Defines help dialog view
     */
    HelpView = Backbone.View.extend({
        el: '#helpDialog',
        template: _.template($('#help-template').html()),
        events: {
            'click .closeButton': 'closeDialog'
        },
        render: function () {
            if (TimeLedger.user.contractors.length > 0) {
                TimeLedger.userContracts = TimeLedger.user.contractors.first().contracts // user is a delegate
            }
            this.$el.html(this.template(JSON.parse(JSON.stringify(TimeLedger.app.contract.division || TimeLedger.app.contract.attributes.division))))
            this.$el.show()
            return this
        },
        closeDialog: function () {
            this.$el.hide()
        }
    })

    /**
     * Defines login view
     */
    LoginView = Backbone.View.extend({
        el: '#loginView',
        template: _.template('foo'),
        events: {
            'click .loginBtn': 'submit',
            'keydown input': 'keyEvent'
        },
        render: function () {
            log.debug('LoginView.render()')
            this.$el.show()
            //      $(location).attr('href', '#loginView');
            // window.location.href = '#loginView';
            return this
        },
        keyEvent: function (e) {
            if (e.keyCode === ENTER_KEY) {
                this.submit()
            }
        },
        submit: function () {
            var username = this.$el.find('input[name="username"]').val()
            var password = this.$el.find('input[name="password"]').val()
            var loginData = {
                username: username,
                password: password
            }
            $.ajax({
                url: TimeLedger.apiBaseUrl + '/login',
                type: 'POST',
                data: JSON.stringify(loginData),
                dataType: 'json',
                contentType: 'application/json',
                success: this.loginSuccess,
                error: this.loginFailure,
                context: this
            })
        },
        loginSuccess: function (data, status, xhr) {
            log.debug(data)
            log.debug(status)
            log.debug(xhr)
            var authResponse = {
                id_token: data.access_token,
                refresh_token: data.refresh_token
            }
            sessionStorage.authResponse = JSON.stringify(authResponse)
            TimeLedger.user = new User({
                userName: data.username,
                roles: data.roles
            })
            // store user in sessionStorage:
            sessionStorage.user = JSON.stringify(TimeLedger.user)
            fetchUser()
            this.$el.hide()
            this.$el.find('#loginError').html('')
            this.$el.find('input').removeClass('error')
            $('#userHeader').show()
            // Start a worker for refreshing the access_token:
            // var refreshWorker = new Worker('js/refresh.js');
        },
        loginFailure: function (xhr, errorType, error) {
            log.debug('Error logging in:' + error)
            if (errorType === 'error' && (error === 'Unauthorized' || error === 'Bad Request')) {
                this.$el.find('#loginError').html('Username or password incorrect.')
                this.$el.find('input').addClass('error')
            } else {
                errorHandler({}, xhr, 'Login')
            }
        }
    })

    /**
     * Defines main app view, by binding to existing DOM element.
     */
    AppView = Backbone.View.extend({

        el: '#ledger',

        initialize: function () {
            this.contract = new Contract()
            this.mode = 'timesheet'
            this.hasMultipleJobDefinitions = false
            this.jobDefinitions = []
            this.tasks = []
            this.listenTo(this.contract, 'all', this.updateContracts)
            this.listenTo(this.contract.timesheets, 'change', this.updateTimesheets)
        },

        events: {
            'change #contract': 'selectContract',
            'change #contractor': 'selectContractor',
            'click .helpButton': 'showHelp'
        },

        addOne: function (timesheet) {
            log.debug('addOne(): ' + timesheet.get('startDate'))
            var view = new TimesheetView({
                model: timesheet
            })
            log.debug('addOne(): ' + timesheet)
            this.$('#timesheet-list').append(view.render().el)
            // open up current timesheet: (auto-open)
            // Disabling for now, per Brandon's request on 8/19:
            /*
            if (moment().isBetween(timesheet.get('startDate'), timesheet.get('endDate'))) {
              view.editsave();
            }
            */
            log.debug('addOne(): ' + timesheet)
        },

        addAll: function (timesheets, saveCurrent = true) {
            log.debug('addAll(): ' + JSON.stringify(timesheets))
            log.debug('savecurrent:' + saveCurrent)
            timesheets.each(this.addOne, this)
            $('#spinner').hide()
        },

        tasksForJobDefinition (definitionId) {
            if (!this.jobDefinitions) {
                return []
            }
            let selected = this.jobDefinitions.filter(function (aDefinition) {
                return aDefinition.id === definitionId
            }).shift()

            if (selected) {
                return selected.taskList
            } else {
                return []
            }
        },

        selectJobDefinition (definitionId) {
            var self = this

            let selected = this.jobDefinitions.filter(function (aDefinition) {
                return aDefinition.id === definitionId
            }).shift()

            if (selected) {
                self.tasks = selected.taskList
            } else {
                self.tasks = []
            }
        },

        setContract: function (contract) {
            this.contract = contract
            this.jobDefinitions = this.contract.attributes.jobDefinitions
            if (this.jobDefinitions) {
                if (this.jobDefinitions.length > 1) {
                    this.hasMultipleJobDefinitions = true
                    this.tasks = []
                } else {
                    this.hasMultipleJobDefinitions = false
                    this.tasks = this.jobDefinitions[0].taskList
                }
            } else {
                // a group admin user may have no job definitions
                this.hasMultipleJobDefinitions = false
                this.tasks = []
            }
            if (this.contract.attributes.tasks && this.contract.attributes.tasks.length) {
                this.contract.tasks.reset(this.contract.attributes.tasks)
            }
            if (this.contract.attributes.contractedFacilities && this.contract.attributes.contractedFacilities.length) {
                this.contract.contractedFacilities.reset(this.contract.attributes.contractedFacilities)
            }
            log.debug('this.contract (after): ')
            log.debug(this.contract)
            $('#spinner').show()
            if (TimeLedger.app.user.contractors.length === 0) {
                this.contract.timesheets.url = this.contract.urlRoot + '/' + this.contract.id + '/users/' + this.user.id + '/timesheets'
            } else {
                this.contract.timesheets.url = this.contract.urlRoot + '/' + this.contract.id + '/users/' + (TimeLedger.user.currentContractor === 0 ? this.user.id : TimeLedger.user.currentContractor) + '/timesheets'
            }
            var authResponse = JSON.parse(sessionStorage.authResponse)
            TimeLedger.app.contract.timesheets.fetch({
                headers: {
                    'Authorization': 'Bearer ' + authResponse.id_token
                },
                success: function (timesheets) {
                    log.debug('successfully fetched contract timesheets')
                    TimeLedger.app.render()
                },
                error: function (model, response) {
                    errorHandler(model, response, 'fetch timesheets')
                }
            })
        },

        selectContract: function (e) {
            this.$('#timesheet-list').empty()
            log.debug('selectContract(): ')
            log.debug(e)
            log.debug('this.contract (before): ')
            log.debug(this.contract)
            this.setContract(TimeLedger.userContracts.get($(e.target).val()))
        },

        selectContractor: function (e) {
            this.$('#timesheet-list').empty()
            log.debug('selectContractor(): ' + $(e.target).val())
            TimeLedger.user.currentContractor = $(e.target).val()
            // TODO - this is exact copy except for .get() of the fetchUser - refactor
            TimeLedger.userContracts = TimeLedger.user.contractors.get($(e.target).val()).contracts
            $('#spinner').show()
            TimeLedger.user.contractors.get($(e.target).val()).contracts.fetch({
                success: function (contracts) {
                    log.debug('successfully fetched delegated contracts:')
                    log.debug(contracts)
                    if (contracts && contracts.length > 0) {
                        TimeLedger.app.setContract(contracts.first())
                        userTasks = TimeLedger.user.contractors.first().tasks
                    } else {
                        log.debug('No valid active contracts found')
                        TimeLedger.app.render()
                    }
                },
                error: function (contract, response) {
                    errorHandler(contract, response, 'Contracts')
                }
            })
        },

        updateContracts: function () {
            log.error('updateContracts()!')
            this.render()
        },

        updateTimesheets: function () {
            log.error('updateTimesheets()!')
            this.render()
        },

        render: function () {
            log.debug('render()! timesheets: ')
            log.debug(this)
            $('#spinner').hide()
            this.$('#group-admin').empty()
            this.$('#timesheet-list').empty()
            if (!_.isUndefined(this.contract) && (this.mode === 'group-admin')) {
                this.$('#userHeader').show()
                var view = new GroupPayPeriodsView()
                this.$('#group-admin').html(view.render().el)
            } else if (_.isUndefined(this.contract) ||
                (this.contract instanceof Backbone.Model && !this.contract.attributes.contractedFacilities) ||
                ((this.contract instanceof Backbone.Model && this.contract.attributes.contractedFacilities && this.contract.attributes.contractedFacilities.length === 0) ||
                    (!(this.contract instanceof Backbone.Model) && this.contract.contractedFacilities && this.contract.contractedFacilities.length === 0))) {
                this.$('#timesheet-list').append('<div>No active contracts are available for ' + this.user.attributes.firstName + ' ' + this.user.attributes.lastName + '</div>')
            } else {
                this.addAll(this.contract.timesheets, false)
            }
            this.$('#userHeader').html(_.template($('#header-template').html()))
            this.$('#userHeader').show()
            if (this.contract.id) {
                this.$el.find('[id=contract]').val(this.contract.id)
            }
            this.$('#userHeader').find('.select-spinner').hide()
        },

        showGroupAdmin: function () {
            this.mode = 'group-admin'
            this.$('#timesheet-list').toggle()
            this.render()
        },

        showTimesheet: function () {
            this.mode = 'timesheet'
            this.$('#timesheet-list').toggle()
            this.$('#group-admin').empty()
            this.render()
        },

        login: function () {
            this.$('#userHeader').empty()
            this.$('#timesheet-list').empty()
            var loginView = new LoginView()
            loginView.render()
        },

        logout: function () {
            TimeLedger.logout(this)
        },

        rejectTimesheet: function () {
            var self = this
            var timesheetId = $('#rejectGroupTimesheetId').val()
            var comments = $('#rejectCommentsTextarea').val()
            var params = { comments: comments }
            var authResponse = JSON.parse(sessionStorage.authResponse)
            $.ajax({
                url: TimeLedger.api2BaseUrl + '/group/return/timesheet/' + timesheetId,
                type: 'POST',
                dataType: 'json',
                data: JSON.stringify(params),
                contentType: 'application/json',
                headers: {
                    'Authorization': 'Bearer ' + authResponse.id_token
                },
                success: function (data) {
                    alert('Timesheet has been rejected.')
                    $('#rejectCommentsModal').hide()
                    self.render()
                },
                error: function (xhr, errorType, error) {
                    alert('Error rejecting timesheet: ' + error)
                }
            })
        },

        recallGroup: function (payperiodId) {
            var self = this
            var authResponse = JSON.parse(sessionStorage.authResponse)
            $.ajax({
                url: TimeLedger.api2BaseUrl + '/group/timesheets/' + payperiodId + '/recall',
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                headers: {
                    'Authorization': 'Bearer ' + authResponse.id_token
                },
                success: function (data) {
                    alert('Group timesheet has been recalled.')
                    self.render()
                },
                error: function (xhr, errorType, error) {
                    alert('Error recalling group timesheet: ' + error)
                }
            })
        },

        submitGroup: function (payperiodId, hasOpenTimesheetsWithEntries) {
            var self = this

            if (hasOpenTimesheetsWithEntries === true) {
                $('#gasubmission-' + payperiodId).show()
            } else {
                $('#gasubmission-' + payperiodId).hide()
                TimeLedger.app.submitGroupAdminTimesheet(payperiodId, self)
            }
        },

        closeGaSubmissionModal: function (payperiodId) {
            $('#gasubmission-' + payperiodId).hide()
        },

        submitGroupAdminTimesheet: function (payperiodId, self) {
            var authResponse = JSON.parse(sessionStorage.authResponse)
            $.ajax({
                url: TimeLedger.api2BaseUrl + '/group/submit/' + payperiodId,
                type: 'POST',
                dataType: 'json',
                contentType: 'application/json',
                headers: {
                    'Authorization': 'Bearer ' + authResponse.id_token
                },
                success: function (data) {
                    alert('Group has been submitted.')
                    $('#gasubmission-' + payperiodId).hide()
                    TimeLedger.app.showGroupAdmin()
                },
                error: function (xhr, errorType, error) {
                    $('#gasubmission-' + payperiodId).hide()
                    alert('Error submitting group: ' + xhr.responseJSON.message)
                }
            })
        },

        rejectGroupTimesheet: function (timesheetId) {
            var commentView = new GroupRejectCommentsView()
            commentView.render({timesheetId: timesheetId})
        },

        showHelp: function () {
            var helpView = new HelpView()
            helpView.render()
        }

    })

    // start app:
    //  log.debug('starting app...');
    //  app = new AppView();

    return new AppRouter()
}

var isContractHavingOpenReviewOrRejectedTimesheets = function (cont) {
    var filteredTimesheets = cont.get('timesheets').filter(ts => ['OPEN', 'REVIEW', 'REJECTED'].includes(ts.status))
    return filteredTimesheets.length > 0
}
var getDefaultContract = function () {
    var defaultContract = null
    TimeLedger.user.contracts.each(function (cont) {
        if (defaultContract == null) defaultContract = cont
        if (isContractHavingOpenReviewOrRejectedTimesheets(cont)) {
            if (defaultContract.get('status') !== 'EXPIRED') {
                if (cont.get('status') === 'EXPIRED') defaultContract = cont
                else if (moment(cont.get('effectiveDate')).isBefore(moment(defaultContract.get('effectiveDate')))) {
                    defaultContract = cont
                }
            } else {
                var openTimesheets = isContractHavingOpenReviewOrRejectedTimesheets(defaultContract)

                if (openTimesheets && cont.get('status') === 'EXPIRED') {
                    if (moment(cont.get('effectiveDate')).isBefore(moment(defaultContract.get('effectiveDate')))) {
                        defaultContract = cont
                    }
                } else if (!openTimesheets) {
                    defaultContract = cont
                }
            }
        }
    })
    console.log('returning default contract')
    return defaultContract
}

var fetchUser = function () {
    TimeLedger.user.fetch({
        error: function (model, response) {
            errorHandler(model, response, 'FetchUser')
        },
        success: function () {
            log.debug('successfully fetched user')
            TimeLedger.user.currentContractor = 0
            TimeLedger.app.user = TimeLedger.user
            // userTasks = user.tasks;
            // userTimesheets = user.timesheets;
            $('#spinner').show()
            if (TimeLedger.user.contractors.length > 0) {
                // user is a delegate
                // assume delegate can be delegate for many contractors and allow
                // specific contractor to be selected.
                // for now, just pick the first:
                TimeLedger.userContracts = TimeLedger.user.contractors.first().contracts
                TimeLedger.user.currentContractor = TimeLedger.user.contractors.first().get('id')
                let contractor = TimeLedger.user.contractors.first()
                contractor.contracts.fetch({
                    success: function (contracts) {
                        log.debug('successfully fetched delegated contracts:')
                        log.debug(contracts)
                        if (contracts && contracts.length > 0) {
                            TimeLedger.app.setContract(contracts.first())
                            userTasks = TimeLedger.user.contractors.first().tasks
                        } else {
                            log.debug('No valid active contracts found')
                            TimeLedger.app.render()
                        }
                    },
                    error: function (contract, response) {
                        errorHandler(contract, response, 'Contracts')
                    }
                })
            } else { // User is Contractor:
                TimeLedger.userContracts = TimeLedger.user.contracts
                if (TimeLedger.userContracts.length) {
                    var defaultContract = getDefaultContract()
                    TimeLedger.app.setContract(defaultContract)
                    if (TimeLedger.app.contract === undefined) {
                        log.debug('No valid active contracts found')
                        TimeLedger.app.render()
                    }
                    // Try and create timesheets
                    var authResponse = JSON.parse(sessionStorage.authResponse)
                    $.ajax({
                        url: TimeLedger.api2BaseUrl + '/users/' + TimeLedger.app.user.get('id') + '/timesheets/',
                        type: 'POST',
                        headers: {
                            'Authorization': 'Bearer ' + authResponse.id_token
                        },
                        success: function (data, status, xhr) {
                            console.log('Completed timesheet creation')
                        },
                        error: function (xhr, errorType, error) {
                            console.log('Ignoring error on create timesheets', error)
                        }
                    })
                } else {
                    TimeLedger.userContracts.fetch({
                        success: function (contracts) {
                            if (contracts.length) {
                                TimeLedger.app.setContract(TimeLedger.user.contracts.first())
                            } else {
                                log.debug('No valid active contracts found')
                                TimeLedger.app.render()
                            }
                        }
                    })
                }
            }
        }
    })
}

/**
 * Helper function for parsing the JWT.
 */
/* does not appear to be used
function parseJwt (token) {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(window.atob(base64))
}
*/
/**
 * Helper function to fill in Zepto gap for scrollTop animation
 *
 * Taken from https://gist.github.com/benjamincharity/6058688
 */
// eslint doesn't like it calling itself
// eslint-disable-next-line no-unused-vars
function smoothScroll (el, to, duration) {
    if (duration < 0) {
        return
    }
    var difference = to - $(window).scrollTop()
    var perTick = difference / duration * 5
    this.scrollToTimerCache = setTimeout(function () {
        if (!isNaN(parseInt(perTick, 5))) {
            window.scrollTo(0, $(window).scrollTop() + perTick)
            smoothScroll(el, to, duration - 5)
        }
    }, 5)
}

function errorHandler (model, response, caller) {
    log.error('Error fetching ' + caller + ':' + response.responseText)
    var error = 'Uknown error occurred.  HTTP status ' + response.statusText
    var retry = false

    switch (response.status) {
    case 0:
        error = 'Request failed to complete.  This may indicate an issue with the API layer or a network problem.'
        retry = true
        break
    case 401:
        TimeLedger.app.login()
        return
    case 403:
        error = 'Your tiMED access has been locked due to a recent change in your contract status. Please contact your division\'s contract administrator for further information and next steps'
        let errorBody = JSON.parse(response.responseText)
        if (errorBody['message']) {
            error = errorBody['message']
        }
        log.error(response.responseURL)
        retry = false
        break
    case 404:
        error = 'The page you requested was not found.<br />' + response.responseURL
        break
    case 500:
        let template = document.createElement('template')
        template.innerHTML = response.responseText
        if (template.content.querySelector('dl')) {
            error = template.content.querySelector('dl').innerHTML
        } else {
            error = 'Server Error 500'
        }
        break
    default:
        break
    }
    var errortemplate = _.template($('#error-template').html())
    $('#system-error').html(errortemplate({status: response.status, message: error, showRetry: retry}))
    $('#system-error').show()
}

// kick-off:
$(function () {
    initViews()

    // set global AJAX timeout: (45s)
    $.ajaxSettings = $.extend($.ajaxSettings, {
        timeout: 45000,
        cache: false
    })

    // Set up default AJAX error handler:  - this is separate to allow any request to be caught for a 401
    $(document).on('ajaxError', function (e, xhr, options) {
        if (xhr.status === 401) {
            setTimeout(function () { TimeLedger.app.login() }, 1000)
        }
    })

    userTasks = new TaskList()

    var params = _.object(_.compact(_.map(location.search.slice(1).split('&'), function (item) {
        if (item) return item.split('=')
    })))

    /* Handle login process:

       1) If token is being passed in (from end of delegated OAuth process, parse
       the JWT token and fetch the associated user using token.

       2) Else, check for user stored in browser session storage and use it.

       3) Else, prompt user to login.
    */
    if (params.token) {
        var decodedToken = jwt_decode(params.token)
        log.debug('decodedToken:')
        log.debug(decodedToken)
        var refreshToken = params.refresh_token
        var authResponse = {
            id_token: params.token,
            refresh_token: refreshToken,
            expiration: moment.unix(decodedToken.exp)
        }
        sessionStorage.authResponse = JSON.stringify(authResponse)
        TimeLedger.user = new User({
            'userName': decodedToken.sub
        })
        fetchUser()
        sessionStorage.user = JSON.stringify(TimeLedger.user)
    } else if (sessionStorage.user !== undefined && sessionStorage.user !== null) {
    // TODO: add session expiration check
        var sessionUser = JSON.parse(sessionStorage.user)
        TimeLedger.user = new User({
            'userName': sessionUser.userName,
            'roles': sessionUser.roles
        })
        fetchUser()
    } else {
        TimeLedger.app.login()
    }

    // changes For exiting out of the PTT TimeSheet page after 5 mins of Idle time.
    var idleTime = 0
    var timer
    // eslint doesn't like it getting called in a different scope
    // eslint-disable-next-line no-unused-vars
    var datetimeAfterCurserMove = 0

    $(document).ready(function () {
        // Increment the idle time counter every minute.
        timer = setInterval(timerIncrement, 60000) // 1 minute

        // Zero the idle timer on mouse movement.
        $(document).on('mousemove', function (e) {
            idleTime = 0
        })
    })

    function timerIncrement () {
        log.debug('timerIncrement called.  idleTime is: ' + idleTime + ' time: ' + moment().toISOString())
        // If we're in debug mode (level 1), don't increment timer
        if (log.getLevel() >= 3) {
            idleTime = idleTime + 1
        }

        if (idleTime <= 4) {
            if (sessionStorage.authResponse !== null && sessionStorage.authResponse !== undefined) {
                var authResponse = JSON.parse(sessionStorage.authResponse)

                if (authResponse.refresh_token !== null && authResponse.refresh_token !== undefined) {
                    _.delay(function () {
                        $.ajax({
                            url: TimeLedger.apiBaseUrl.replace('/api', '') + '/oauth/access_token',
                            type: 'POST',
                            data: $.param({
                                'grant_type': 'refresh_token',
                                'refresh_token': authResponse.refresh_token
                            }),
                            contentType: 'application/x-www-form-urlencoded',
                            success: function (data, status, xhr) {
                                var authResponse = JSON.parse(sessionStorage.authResponse)
                                var exp = moment()
                                exp.add(data.expires_in, 'seconds')
                                var refresh_token = authResponse.refresh_token
                                sessionStorage.authResponse = JSON.stringify({
                                    id_token: data.access_token,
                                    refresh_token: refresh_token,
                                    expiration: exp
                                })
                            },
                            error: function (data, status, xhr) {
                                log.error('failure.')
                            }
                        })
                    }, 500)
                }
            }
        } else if (idleTime >= 5) {
            clearInterval(timer)
            idleTime = 0
            TimeLedger.logout()
            TimeLedger.app.login()
        }
    }
})
